import {
  Container
} from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ActionModal from "../../components/action-modal";
import ChipSelector from "../../components/chip-selector";
import EnhancedTable, { createColumn } from '../../components/enhanced-table';
import { LocationContext } from '../../context/locationContext';
import useParams from "../../hooks/useParams";
import { deleteUsersApi, exportUsers, getAllUsersPage, getCredentialsByUserIds } from '../../service/usersApi';
import {
  ALL,
  API_REQUEST_ERROR_MESSAGE,
  ASCENDING,
  LAST_NAME,
  NAME,
  USERS,
  USERS_MODULE,
  USER_MODULE
} from "../../utility/constants";
import { parseParams } from "../../utility/helper";
import useStyles from "./styles";
import CredentialsChip from "./users-credentials-chip";
import CsvModal from './users-csv-modal';

export const columns = [
  createColumn("id", "ID", false, "string", true),
  createColumn("credentials", "", false, "component", false, false, true),
  createColumn('name', 'users-page.nameColumn', true, 'string', false, true, true),
  createColumn('username', 'users-page.personalNumberColumn', true, 'numberic'),
  createColumn('validFrom', 'users-page.availableFromColumn', true, 'date'),
  createColumn('validUntil', 'users-page.availableToColumn', true, 'date'),
  createColumn("location", 'users-page.locationColumn', true, "string"),
  createColumn('roles', 'users-page.rolesColumn', false, 'component')
];

const initialParams = {
  listType: ALL,
  size: 100,
  page: 1,
  sort: `${LAST_NAME},${ASCENDING}`,
  keyword: ''
}

const Users = (props) => {
  const { t } = useTranslation();
  const classes  = useStyles();
  const history  = useHistory();
  const { state } = useContext(LocationContext);
  const { selectedLocationIds } = state;

  const [searchParams, setSearchParams] = useParams(initialParams);
  const { sort, listType: listTypeParam, size: sizeParam, page: pageParam, keyword: keywordParam } = searchParams;
  const { showToaster, showLoading, handlePermissions } = props;

  const [keyword, setKeyword]                                               = useState(keywordParam);
  const [isLoading, setIsLoading]                                           = useState(false);
  const [users, setUsers]                                                   = useState([]);
  const [totalUsers, setTotalUsers]                                         = useState(0);
  const [remove, setRemove]                                                 = useState(false);
  const [showModal, setShowModal]                                           = useState(false);
  const [showCSVModal, setShowCSVModal]                                     = useState(false);
  const [exportData, setExportData]                                         = useState('');
  const [associatedCredentials, setAssociatedCredentials]                   = useState([]);
  const [selectedUsers, setSelectedUsers]                                   = useState([]);

  const csvInstance = useRef(null);
  let debounceTimeoutRef = useRef(null);

  const orderBy = parseParams(sort)[0];
  const order = parseParams(sort)[1];
  const listType = listTypeParam.toLowerCase();
  const size = parseInt(sizeParam);
  const page = parseInt(pageParam);

  useEffect(() => {
    if (exportData && csvInstance && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setExportData();
      });
    }
  }, [exportData]);

  const formatUsers = useCallback((data) => {
    return data.map(user => {
      const { credentials, pin, roles } = user;

      return {
        ...user,
        credentials: <CredentialsChip credentials={credentials} pin={pin}/>,
        roles: <ChipSelector label={'users-page.rolesChipName'} data={roles} />,
      }
    });
  }, []);

  const getUsers = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await getAllUsersPage({
        search  : keyword,
        sort    : `${orderBy === NAME ? LAST_NAME : orderBy},${order}`,
        size    : size,
        page    : page,
      });
      const { totalElements } = response.page;
      
      const formattedUsers = formatUsers(response.users);

      setUsers(formattedUsers);
      setTotalUsers(totalElements);
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
      setRemove(false);
    }
  }, [showToaster, t, order, keyword, orderBy, page, size, formatUsers]);

  useEffect(() => {
    getUsers()
  }, [selectedLocationIds, getUsers]);
  
  const handleSearch = (value) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    
    setIsLoading(true);
    setKeyword(value);

    debounceTimeoutRef.current = setTimeout(() => {
      setSearchParams({...searchParams, keyword: value, page: 1});
    }, 1000);
  }

  const handleClearSearch = () => {
    setKeyword('');
    setSearchParams({...searchParams, keyword: '', page: 1});
  }

  const handleSort = (newOrderBy, newOrder) => {
    setSearchParams({...searchParams, sort: `${newOrderBy},${newOrder}`, page: 1});
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setSearchParams({...searchParams, size: newRowsPerPage, page: 1});
  }

  const handleChangePage = (newPage) => {
    if (isLoading) {
      return;
    }

    setSearchParams({...searchParams, page: newPage + 1});
  }

  const handleRowClick = (id) => {
    history.push(`/users/view/${id}`);
  }

  const handleCreate = () => {
    history.push("/users/create");
  }

  const handleUpdate = (id) => {
    history.push(`/users/update/${id}`);
  }

  const getAssociatedCredentials = async (values) => {
    try {
      const userIds = values.map(credential => credential.id).join(',');
      const response = await getCredentialsByUserIds(userIds);
      const credentials = response.data.credentials.map(credential => {
        return {
          id  : credential.credentialId,
          name: credential.name
        }
      });
      
      return credentials;
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const handleDelete = async (values) => {
    const users = Array.isArray(values) ? values : [values];
    const associatedCredentials = await getAssociatedCredentials(users);
    const hasAssociatedCredentials = associatedCredentials.length > 0;
    
    const selectedUsers = users.map(user => {
      return {
        id    : user.id,
        name  : user.name
      }
    })
    setSelectedUsers(selectedUsers);
    if (hasAssociatedCredentials) {
      setAssociatedCredentials(associatedCredentials);
    }
    setShowModal(true);
  }

  const handleConfirmDeletion = async (isDetached) => {
    try {
      const ids = selectedUsers.map(users => users.id);
      const deleteAll = !isDetached
      await deleteUsersApi(ids, deleteAll);
      getUsers();
      
      const users = selectedUsers.map(users => users.name);
      let toasterMessage = `${users[0]} ${t('hasBeenDeleted')}`;
      if (selectedUsers.length > 1) {
        const lastName = users.pop();
        toasterMessage = `${users.join('; ')}, ${t('and')} ${lastName} ${t('hasBeenDeleted')}`
      } 
      
      showToaster(t('success'), toasterMessage, 'success');
      setRemove(true);
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      handleCloseDeleteModal();
    }
  }

  const handleCloseDeleteModal = () => {
    setAssociatedCredentials([]);
    setSelectedUsers([]);
    setShowModal(false);
  }

  const handleImport = () => {
    setShowCSVModal(true);
  }

  const handleExport = async () => {
    const data = await exportUsers();
    setExportData(data);
  }

  const csvExportHeader = [
    {label: '#personalnumber', key: 'personalNumber'},
    {label: 'firstname', key: 'firstName'},
    {label: 'lastname', key: 'lastName'},
    {label: 'credential', key: 'credential'},
    {label: 'validfrom', key: 'validFrom'},
    {label: 'validuntil', key: 'validUntil'},
    {label: 'role-name', key: 'roleName'},
    {label: 'location', key: 'location'}
  ]

  const getFilename = () => {
    return (`${t('exportedUsers')}.csv`);
  }
  
  return (
    <Container maxWidth="xl" className={classes.container}>
      { exportData ? <CSVLink data={exportData} ref={csvInstance} headers={csvExportHeader} filename={getFilename()} /> : undefined }
      <CsvModal
        showToaster={showToaster}
        showModal={showCSVModal}
        setShowModal={setShowCSVModal}
        setRemove={setRemove}
        showLoading={showLoading}
      />
      <ActionModal
        title={t('user-delete-action-modal.title')}
        isOpen={showModal}
        onClose={handleCloseDeleteModal}
        description={
          associatedCredentials.length > 0 ?
            t('user-delete-action-modal.descriptionWithCredentials', {userCount : selectedUsers.length, credentialCount: associatedCredentials.length})
          : selectedUsers.length > 1 ?
            t('user-delete-action-modal.descriptionMultipleUsers', {userCount : selectedUsers.length})
          :
            t('user-delete-action-modal.descriptionSingleUser')
        }
        entities={associatedCredentials.length > 0 ? associatedCredentials : selectedUsers}
        onEntityClick={() => {}}
        onSubmit={handleConfirmDeletion}
        footerDescription={associatedCredentials.length > 0 && t('user-delete-action-modal.footerDescription')}
        cancelButtonLabel={t('user-delete-action-modal.cancelButtonLabel')}
        submitButtonLabel={selectedUsers.length > 1 ? t('user-delete-action-modal.submitButtonLabelMultiple') : t('user-delete-action-modal.submitButtonLabelSingle')}
      />
      <EnhancedTable
        title={t(USERS)}
        withCheckbox
        withAction
        listType={listType}
        columns={columns}
        data={users}
        isLoading={isLoading && !remove}
        isTotalItemsLoading={isLoading}
        keyword={keyword}
        label={USER_MODULE}
        module={USERS_MODULE}
        onChangePage={handleChangePage}
        onClearSearch={handleClearSearch}
        onCreate={handleCreate}
        onImport={handleImport}
        onExport={handleExport}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        onMultipleDelete={handleDelete} 
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearch={handleSearch}
        onSort={handleSort}
        orderBy={orderBy}
        order={order}
        page={page}
        rowsPerPage={size}
        totalItems={totalUsers}
        handlePermissions={handlePermissions}
        handleRowClick={handleRowClick}
      />
    </Container>
  );
}

export default Users;